import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import ContextConsumer from "src/layouts/Context.js"
import SEO from "src/components/global/SEO.js"
import distances from "src/assets/styles/distances.js"
import mediaQuery from "src/assets/styles/mediaQuery"
import noHangers from "src/components/global/fn/noHangers.js"
import Navigation from "src/components/global/Navigation.js"
import Footer from "src/components/global/Footer"
import NavCompetence from "src/components/whatWeDo/NavCompetence.js"
import NavProjects from "src/components/whatWeDo/NavProjects.js"
import ProjectCard from "src/components/whatWeDo/ProjectCard.js"
import Wrapper from "src/components/global/Wrapper"
// import Crop from "src/components/global/Crop"
import ReactMarkdown from "react-markdown"

import BigTextH3 from "src/components/global/typography/BigTextH3.js"
import MainBtn from "src/components/global/btn/MainBtn"
import SmallTitle from "src/components/global/typography/smallTitle.js"
import MidText from "src/components/global/typography/MidText"
import BodyText from "src/components/global/typography/BodyText.js"
import { useQueryParam, NumberParam, StringParam } from "use-query-params"

import { useLocation } from "react-router-dom"

const CompetenceWrapper = styled(Wrapper)`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  @media (max-width: ${mediaQuery.tablet}) {
    flex-direction: column-reverse;
  }
`

const CompetenceContent = styled.div`
  height: 0;
  overflow: hidden;
  padding-bottom: 0px;
  &.showIndex {
    height: auto;
    padding-bottom: 10px;
  }
  &.show {
    height: auto;
    padding-bottom: 10px;
  }
  /* &.hide {
    height: 0;
    overflow: hidden;
    padding-bottom: 0px;
  } */
`
const CompetenceContentWrapper = styled.div`
  width: 75%;
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
  }
`
const CompetenceTitle = styled(BigTextH3)`
  margin-bottom: ${distances.mainBigMargin}px;
`

const CompetenceFirstP = styled(MidText)`
  margin-bottom: ${distances.mainBigMargin}px;
  .marginP {
    p {
      margin-bottom: 20px;
    }
  }
`

const CompetenceSecondP = styled(BodyText)`
  width: 77.77%;
  .marginP {
    p {
      margin-bottom: 20px;
    }
    p:last-of-type {
      margin-bottom: 0;
    }
  }
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
  }
`

const ProjectWrapper = styled(Wrapper)`
  position: relative;
`

const ProjectsTop = styled.div`
  margin-bottom: 100px;
  display: flex;
  flex-wrap: wrap;
  @media (max-width: ${mediaQuery.tablet}) {
    margin-bottom: 50px;
  }
`

const ProjectsTopLeft = styled(SmallTitle)`
  width: 25%;
`

const ProjectsTopRight = styled.div`
  width: 75%;
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
    position: sticky;
    top: 94px;
  }
`

const WhatWeDo = ({ data, pageContext, location }) => {
  // const [num, setNum] = useQueryParam("index", NumberParam)
  const newData = data
  // let UrlIndex = 100000
  const [UrlIndex, setNum] = useState(100000)
  // const [num, setNum] = useQueryParam("x", NumberParam)
  useEffect(() => {
    if (typeof window !== "undefined") {
      if (location.hash.split("=")[1]) {
        setNum(location.hash.split("=")[1])
      }
    }
  }, [])

  // https://localhost:8000/?parameter1=firstParam&parameter2=secondParam
  // const location = useLocation()
  // const queryParams = new URLSearchParams(location.search)

  // console.log(queryParams.get("index"))
  // const [foo, setFoo] = useQueryParam("index", StringParam)
  // const [num, setNum] = useQueryParam("index", NumberParam)
  // checkCategory = (itemCategory, activeCategory) => {
  //   if (itemCategory === activeCategory) {
  //     console.log(true)
  //     return true
  //   }
  // }

  // render() {
  return (
    <>
      <SEO
        title={newData.strapiWhatWeDoPage.SEO?.SEO_Title}
        description={newData.strapiWhatWeDoPage.SEO?.SEO_Description}
        image={newData.strapiWhatWeDoPage.SEO.Seo_Img.publicURL}
      />
      <Navigation lang={pageContext.locale} langUrl={pageContext.langUrl} />
      <CompetenceWrapper id="CompetenceWrapper">
        <NavCompetence lang={pageContext.locale} location={location} />

        <ContextConsumer>
          {({ data, set }) => (
            <CompetenceContentWrapper>
              {newData.allStrapiCompetences.edges.map((document, index) => (
                <CompetenceContent
                  // className={`${
                  //   data.competence === document.node.strapiId ? "show" : "hide"
                  // } ${
                  //   index === 0 && data.competence == 9999 ? "showIndex" : null
                  // }`}
                  className={`${
                    UrlIndex == document.node.strapiId &&
                    data.competence == 9999
                      ? "show"
                      : ""
                  } ${
                    data.competence == document.node.strapiId ? "show" : "hide"
                  } ${
                    index === 0 && data.competence == 9999 && UrlIndex == 100000
                      ? "showIndex"
                      : ""
                  }`}
                >
                  <CompetenceTitle>
                    {noHangers(document.node.Title)}
                  </CompetenceTitle>

                  <CompetenceFirstP>
                    <ReactMarkdown
                      className={"marginP"}
                      source={noHangers(document.node.First_paragraph)}
                    />
                  </CompetenceFirstP>
                  <CompetenceSecondP>
                    <ReactMarkdown
                      className={"marginP"}
                      source={noHangers(document.node.Second_paragraph)}
                    />
                  </CompetenceSecondP>
                  <MainBtn
                    hoverText={document.node.Btn.Hover}
                    Mobile={document.node.Btn.Mobile}
                    link={
                      pageContext.locale == "pl" ? "/kontakt" : "/en/contact"
                    }
                  >
                    {document.node.Btn.Main}
                  </MainBtn>
                </CompetenceContent>
              ))}
            </CompetenceContentWrapper>
          )}
        </ContextConsumer>
      </CompetenceWrapper>
      {pageContext.locale !== "en" && (
        <ContextConsumer>
          {({ data, set }) => (
            <ProjectWrapper id="Project">
              <ProjectsTop>
                <ProjectsTopLeft>projekty</ProjectsTopLeft>
                <ProjectsTopRight>
                  <SmallTitle noColorMobile>filtruj</SmallTitle>

                  <NavProjects />
                </ProjectsTopRight>
              </ProjectsTop>

              {newData.allStrapiProjects.edges.map(document => (
                <ProjectCard
                  active={data.projectCategory}
                  img={document.node.Thumbnail.localFile.childImageSharp.fluid}
                  title={noHangers(document.node.Title)}
                  competences={document.node.competences}
                />
              ))}
            </ProjectWrapper>
          )}
        </ContextConsumer>
      )}
      <Footer lang={pageContext.locale} />
    </>
  )
  // }
}

export const query = graphql`
  query MyQuery($locale: String!) {
    strapiWhatWeDoPage(locale: { eq: $locale }) {
      SEO {
        SEO_Title
        SEO_Description
        Seo_Img {
          localFile {
            publicURL
          }
        }
      }
    }
    allStrapiCompetences(
      filter: { locale: { eq: $locale } }
      sort: { order: ASC, fields: Title }
    ) {
      edges {
        node {
          Name
          strapiId
          Title
          First_paragraph
          Second_paragraph
          Btn {
            Hover
            Main
            Mobile
          }
        }
      }
    }
    allStrapiProjects(sort: { fields: created_at, order: DESC }) {
      edges {
        node {
          Title
          Thumbnail {
            localFile {
              childImageSharp {
                fluid(
                  maxWidth: 500
                  traceSVG: { background: "#ffffff", color: "#ffff00" }
                ) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
          }
          competences {
            Name
          }
        }
      }
    }
  }
`

export default WhatWeDo
