import React from "react"
import styled from "styled-components"
import { StaticQuery, graphql } from "gatsby"
import ContextConsumer from "src/layouts/Context.js"

import typographySizes from "src/assets/styles/typographySizes.js"
import colors from "src/assets/styles/colors.js"

const NavProjectsWrapper = styled.nav`
  width: 100%;
`

const NavProjectsUl = styled.ul`
  list-style: none;
  display: inline-flex;
  flex-wrap: wrap;
  flex-direction: row;
`
const NavProjectsCheckboxDot = styled.div`
  height: 8px;
  width: 8px;
  border-radius: 100%;
  margin-top: 2px;
  background-color: ${colors.mainColor};
  transition: all 0.5s;
  transform: scale(0);

  &.active {
    transform: scale(1);
    transition: all 0.5s cubic-bezier(0.17, 0.67, 0.03, 1.33);
  }
`

const NavProjectsElement = styled.li`
  font-size: ${typographySizes.s}px;
  color: ${colors.darkGrey};
  padding: 7.5px 0;
  margin-right: 25px;
  text-transform: lowercase;
  cursor: pointer;
  display: inline-block;
  display: flex;

  :hover ${NavProjectsCheckboxDot} {
    transform: scale(0.5);
    transition: all 0.5s cubic-bezier(0.17, 0.67, 0.03, 1.33);
  }
  :hover .active {
    transform: scale(1);
    transition: all 0.5s cubic-bezier(0.17, 0.67, 0.03, 1.33);
  }
`

const NavProjectsCheckbox = styled.div`
  display: flex;
  align-items: center;
  span {
    margin: 0 3px;
  }
`

const NavProjects = props => (
  <StaticQuery
    query={graphql`
      query NavProjectsQuery {
        allStrapiCompetences(
          filter: { locale: { eq: "pl" } }
          sort: { order: ASC, fields: Name }
        ) {
          edges {
            node {
              Name
              strapiId
            }
          }
        }
      }
    `}
    render={dataQuery => (
      <NavProjectsWrapper>
        <ContextConsumer>
          {({ data, set }) => (
            <NavProjectsUl>
              <NavProjectsElement
                onClick={() => set({ projectCategory: "all" })}
              >
                <NavProjectsCheckbox>
                  <span>[</span>
                  <NavProjectsCheckboxDot
                    className={data.projectCategory === "all" ? "active" : ""}
                  />
                  <span>]</span>
                </NavProjectsCheckbox>
                wszystkie
              </NavProjectsElement>
              {dataQuery.allStrapiCompetences.edges.map(document => (
                <NavProjectsElement
                  onClick={() =>
                    set({
                      projectCategory: document.node.Name,
                    })
                  }
                  className={
                    document.node.Name === data.projectCategory
                      ? "active"
                      : null
                  }
                >
                  <NavProjectsCheckbox>
                    <span>[</span>
                    <NavProjectsCheckboxDot
                      className={
                        data.projectCategory === document.node.Name
                          ? "active"
                          : ""
                      }
                    />
                    <span>]</span>
                  </NavProjectsCheckbox>
                  {document.node.Name}
                </NavProjectsElement>
              ))}
            </NavProjectsUl>
          )}
        </ContextConsumer>
      </NavProjectsWrapper>
    )}
  />
)

export default NavProjects
